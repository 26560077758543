<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{ $t("message.late_to_works") }}</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.staff") }}
                </span>
                <el-form-item prop="staff_id" size="medium">
                   <select-staff
                    :id="form.staff_id"
                   
                    v-model="form.staff_id"
                  ></select-staff>
                 </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.reason") }}
                </span>
                <el-form-item prop="reason_id" class="label_mini">
                   <div class="seloction mb20">
                      <el-select
                        v-model="form.reason_id"
                        :placeholder="$t('message.reason')"
                        class="w-100"
                        :size="'small'"
                        popper-class="my-data-khan"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                        <el-option
                          v-for="(reason, index) in reasons"
                          :key="'guestreason-' + index"
                          :label="reason.name"
                          :value="reason.id"
                        ></el-option>
                      </el-select>
                    </div>
                </el-form-item>
              </el-col>
               <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.arrival_time") }}
                </span>
                <el-form-item prop="coming_time" class="label_mini">
                  <el-time-picker
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    size="small"
                    :placeholder="$t('message.arrival_time')"
                    v-model="form.coming_time"
                    style="width: 100%"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                  <select-company
                    :id="form.branch_id"
                    v-model="form.branch_id"
                  ></select-company>
              </el-col>
              <!-- end col -->
              <el-col :span="24">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.comment") }}
                  </span>
                  <el-form-item prop="comment" class="label_mini">
                   <el-input
                        type="textarea"
                        :placeholder="$t('message.comment')"
                        v-model="form.comment"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-input>
                  </el-form-item>
                </div>
              </el-col>
             
            
             
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
   
    
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCompany from "@/components/filters/inventory/select-or-hidden-company";
import selectGuestCarModel from "@/components/filters/inventory/select-guest-car-model";
import selectStaff from "@/components/filters/inventory/select-staff";
import selectGender from "@/components/filters/inventory/select-gender";


export default {
  mixins: [form, drawer],
  components: { selectGender, selectCompany },
  data() {
    return {
       dialogCreateModel: false,
      loadingButton: false,
      loadingData:false,

    };
  },
  props: {
    selectedItem: {
      required: true,
      type: Object,
    },
  },
  mounted() {
     this.reasonsAction();
  },
  computed: {
    ...mapGetters({
      rules: "lateToWorks/rules",
      model: "lateToWorks/model",
      columns: "lateToWorks/columns",
      reasons: "lateToWorks/reasons",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "lateToWorks/update",
      show: "lateToWorks/show",
      empty: "lateToWorks/empty",
      reasonsAction: "lateToWorks/reasons",
      updateList:"lateToWorks/index"
    }),
     afterOpen() {      
      this.fetchData();
    },
     submit() {
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {                
                this.form = JSON.parse(
                  JSON.stringify({
                    full_name: "",
                    gender_id: null,
                    additional_information: "",
                  })
                );
                this.parent().updateList();
                this.updateList();
                this.close();
                
                this.$parent.$parent.dialogCreateGuest = false;               
              }
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
       fetchData() {
        if (!this.loadingData) {
          this.loadingData = true;
          this.show(this.selectedItem.id)
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
        }
    },
  },
};
</script>

